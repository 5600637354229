// Section / *
.section {
  padding: 40px 0;
}

.section-title {
  padding-bottom: $section-spacer-y * 0.66;
  position: relative;

  &:before {
    background-color: $brand-primary;
    bottom: -2px;
    content: '';
    position: absolute;
    height: 4px;
    width: 110px;
  }

  a {
    @extend .link-reset; // .link-default ?
  }
}

.section-block {
  padding: 0;
}

.section-header {
  padding-top: 0;
}

.section-footer {
  padding-bottom: 0;
}

.section-composite {
  margin-bottom: $section-spacer-y; // When composite, it does not get the margin.
}

.section-block-form {
  padding-bottom: $section-spacer-y - $form-group-margin-bottom;
}

.section-reset {
  padding-bottom: 0;

  @media screen and (min-width: $screen-sm-min) {
    .section-block {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.section-text--secondary {
  color: $section-text-color-muted;
}

.section-btn {
  @include button-huer(
    #fff,             // $color,
    #fff,             // $color-hover,
    $brand-secondary, // $bg,
    #9ea0a4,          // $bg-hover,
    transparent,      // $border-color,
    transparent,      // $border-hover,
    $brand-secondary  // $color-active: $color,
  );

  display: block;
  min-width: 300px;
  padding: 6px 12px;
  position: relative;

  .section-btn-icon {
    vertical-align: middle;

    .wizicon {
      @include size(13px, 13px);
      fill: #fff;
    }
  }

  .section-btn-text {
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    text-align: center;
    vertical-align: middle;
  }
}

.section--jumbotron-channel {
  padding: 0;
  margin-bottom: 0;

  .section-container {
    padding: 0;
    width: auto;

    @media (min-width: $screen-sm-min) {
      width: auto;
    }

    @media (min-width: $screen-md-min) {
      width: auto;
    }

    @media (min-width: $screen-lg-min) {
      width: auto;
    }
  }
}

.section--header {
  padding: 20px 0;
  margin-bottom: 0;

  .section-img {
    margin-bottom: 10px;
  }

  .section-title {
    border: 0;
    margin-bottom: 7px;
    padding: 0;

    font-size: 16px; // @TODO
    font-weight: 600;
    text-transform: none;
    text-align: center;
  }

  .section-text {
    text-align: center;
  }

  @media (min-width: $screen-sm) {
    display: none;
  }
}

.section--header-2 {
  .section-img {
    > img {
      margin-left: 0;
    }
  }

  .section-title,
  .section-text {
    text-align: left;
  }
}

.section--header-3 {
  .section-title,
  .section-text {
    text-align: left;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    .section-img {
      margin-left: 0;
      width: 50%;

      > img {
        margin-left: 0;
      }
    }

    .section-block {
      bottom: 0px;
      position: absolute;
      right: 0;

    }

    .section-title,
    .section-text {
      text-align: right;
    }
  }
}

.section--news.section--highlight {
  .section-block {
    padding: 0;
  }
}

.section-header.section-header--with-options {
  @include clearfix();

  border-bottom: 1px solid $border-color;
  margin-bottom: $section-cap-y;
  padding-bottom: 0;

  .section-header-widget {
    display: none;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: block;
      float: right;
    }
  }

  .section-title {
    border-bottom-width: 0;
    overflow: hidden;
  }
}

.section-composite {
  position: relative;
}

.section--boilerplate {
  padding: 40px 0;
  margin-bottom: 0;

  .section-img {
    margin-bottom: 10px;

    img {
      margin: auto;
      display: block;
      width: 200px;
    }
  }

  .section-header {
    display: none;
  }

  .section-title {
    @include make-font-10();
    border-bottom: 0;
  }

  .section-block {
    @include make-font-1();
    padding-top: 0;

    @media screen and (min-width: $grid-float-breakpoint) {
      @include make-columns(2, 2em);
    }
  }

  .section-text {
    a {
      color: $brand-white;
      font-size: $font-size-sm;
    }
  }
}

.section-languages {
  text-align: right;

  .section-header-widget-item {
    display: inline-block;
  }
}

.section--newsletter {
  .form-submit {
    @include make-block-center(25%);
    @include make-button--primary-1();

    @media screen and (max-width: $screen-sm) {
      width: initial;
    }
  }
}

.layout-hook--footer .section--newsletter {
  background-image: url('../../../../images/events_background.png');
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid $border-color;
  margin-bottom: 0;

  .section-title {
    @include make-font-10();
    border-bottom: none;
    text-align: left;
  }

  .layout--newsletter-index & {
    display: none;
  }
}

.section--contactform {
  @media screen and (min-width: $screen-sm-min) {
    .card--contactform {
      @include make-block-center(75%);
    }
  }
}

.section--main,
.section--sidebar {
  margin-bottom: 0;
}

.section--jumbotron-content {
  margin: 0;
  padding: 0; // @TODO

  .section-background {
    background-color: $brand-secondary;
    display: none;
    height: 270px;
    overflow: hidden;
    position: relative;

    img {
      bottom: -5%;
      filter: url(#ff-blur);
      left: -5%;
      max-width: none;
      position: absolute;
      right: -5%;
      top: -33%;
      width: 110%; // Let the img overflow, since there's some blur on it.
    }
  }

  .section-title {
    @include make-font-title--1();

    border-bottom: none;
    font-weight: 400;
    margin-bottom: 10px;
    padding-bottom: 0;
    text-transform: none;
  }

  .section-subtitle {
    @include make-font-subtitle--1();
  }

  .section-text {
    @include make-font-text--primary-highlight();
  }

  @media screen and (max-width: $grid-float-breakpoint-max) {
    border-top: 1px solid $border-color;
    padding-top: 20px;

    .js-sharing-affix {
      position: static !important; // To not
    }
  }

  @media screen and (min-width: $screen-sm) {
    .section-wrapper {
      background-color: #fff;
      margin-top: -160px;
      min-height: 160px; // Compensate the margin-top, important!
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 40px;
      z-index: 1;
    }

    .section-background {
      display: block;
    }

    .js-sharing-affix {
      left: -80px;
      position: absolute; // Positioned relative to the section-wrapper.
      top: 180px;
      width: 80px;

      &.affix {
        position: fixed;
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    .section-wrapper {
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 65px;
    }
  }
}

.section--jumbotron-event {
  padding: 0; // @TODO

  .section-background {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;

    &:after {
      background-color: #000;
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.5;
      position: absolute;
      right: 0;
      top: 0;
    }

    > img {
      width: 100%;

      @media (max-width: $screen-sm-max) {
        height: 100%;
        max-width: none;
        width: auto;
      }
    }
  }

  .section-block {
    padding: 40px 0;
    position: relative; // Doesn't work without this.
  }

  .section-title {
    border-color: $section-hero-text-color;
    color: $section-hero-text-color;
    font-size: $font-size-xxxl;
    font-weight: $font-weight-regular;
    text-align: center;
  }

  .section-subtitle,
  .section-text,
  .section-text--secondary {
    color: $section-hero-text-color;
    font-size: 16px;
    text-align: center;
  }

  .section-bottombar {
    background-color: rgba(0, 0, 0, 0.5);
    position: relative; // important for the whole stack to work.
  }

  .section-bottombar-body {
    padding: 40px 10px 20px 10px;
  }

  .section-btn {
    @include make-button--primary-2();
    color: #fff;
    margin-bottom: 0;

    &:hover {
      color: #fff;
    }

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: block;
      min-width: auto;
    }
  }

  .mediaobj--eventinfo {
    .mediaobj-body {
      color: #fff;
      font-size: 16px;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      text-align: center;

      .mediaobj-object {
        display: inline-block;
        float: none;
        margin: auto;
      }

      .mediaobj-body {
        text-align: center;
      }
    }
  }
}

.layout-expert {
  &.layout--expert-show {
    .section--jumbotron-expert {
      .section-background {
        display: none;
      }

      .section-wrapper {
        margin-top: 0;
        padding: 0;

        .section--features--generic {
          display: none;
        }
      }

      .section-img {
        margin: 0 0 16px;

        img {
          border: 0;
          border-radius: 0;
          height: 100%;
          width: 100%;
        }
      }

      .section-title {
        font-family: $font-family-maax-publicsenat-bold;
        font-size: $font-size-xxl;
        margin-bottom: 16px;
        line-height: 34px;
      }

      .section-subtitle {
        font-family: $font-family-maax-regular;
        margin-bottom: 16px;
        line-height: 18px;
      }

      .section-element--tags {
        .label--tags {
          background: #EBEBEB;
          border-color: #EBEBEB;
          color: $brand-primary;
          font-family: $font-family-maax-publicsenat-bold;
          font-size: 11px;
          line-height: 13px;
          padding: 4px 6px 1px;
        }
      }

      .section-text {
        font-family: $font-family-maax-regular;
        font-size: $font-size-lg;
        margin-bottom: 40px;
        line-height: 24px;
      }

      .section-element--webidentites {
        display: none;
      }

      @media screen and (min-width: $screen-sm) {
        .section-wrapper {
          padding: 50px 0 0 52px;
        }

        .section-img {
          float: left;
          margin: 0 40px 0 0;
          height: 338px;
          width: 338px;
        }

        .section-block {
          overflow: hidden;
        }

        .section-element--tags {
          margin-bottom: 40px;
        }
      }
    }
  }

  .layout-hook {
    &.layout-hook--content {
      padding-top: 0;

      .section-wrapper {
        @media screen and (min-width: $screen-md) {
          min-width: 100%;
          padding-left: 430px;
        }
      }

      .section--expertises {
        .section-header {
          display: none;
        }

        .panel--expert_expertise {
          &::after {
            display: none;
          }

          &.in {
            .panel-heading,
            .panel-collapse {
              background-color: #EBEBEB;

              .wizicon-nav-indicator {
                transform: rotate(-180deg);
                transition: transform ease-in 0.3s, -webkit-transform ease-in 0.3s;
              }
            }
          }

          .panel-widget-item {
            padding: 24px;

            .wizicon {
              height: 10px;
              width: 10px;
            }
          }

          .panel-widget--dates {
            padding-right: 16px;
            width: 156px;

            .panel-widget-item {
              font-family: $font-family-maax-regular;
              font-size: $font-size-xs;
              line-height: 20px;
              padding: 27px 16px 0 24px;
            }
          }

          .panel-title {
            color: $brand-dark;
            font-family: $font-family-maax-publicsenat-bold;
            font-size: $font-size-lg;
            line-height: 19px;
            padding: 24px 0 16px;
          }

          .panel-widget--collapse-indicator {
            .panel-widget-item {
              padding: 21px 20px;
            }
          }

          .panel-body {
            padding: 0 64px 10px 156px;

            p {
              font-family: $font-family-maax-regular;
              font-size: $font-size-lg;
              line-height: 24px;
            }
          }
        }

        @media screen and (max-width: $screen-sm) {
          .panel--expert_expertise {
            .panel-widget--dates {
              width: auto;

              .panel-widget-item {
                padding: 24px 16px 0;
              }
            }

            .panel-title {
              padding: 24px 16px 16px;
            }

            .panel-body {
              padding: 0 64px 10px 16px;
            }
          }
        }
      }
    }
  }
}

.section--searchform {
  background-color: $brand-light;
  border: 1px solid $border-color;
  margin-bottom: 0;
  padding: 0;

  .section-block {
    padding-bottom: $section-spacer-y;
    padding-top: $section-spacer-y;
  }
}

.section--default.section--webidentities {
  .section-header {
    padding-bottom: 0;
  }

  .section-title {
    border-bottom: 0;
    font-size: $font-size-lg;
    text-align: center;
  }

  .list--webidentities {
    text-align: center;
  }
}

.section--videos.section--default {
  .card {
    @include make-card-layout--reset();
  }
}

.section--event_web_publication-content {
  .section-wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: $screen-sm) {
    .section-wrapper {
      max-width: $container-sm - $grid-gutter-width - 80px;
    }
  }

  @media screen and (min-width: $screen-md) {
    .section-wrapper {
      max-width: $container-md - $grid-gutter-width - 200px;
    }
  }

  @media screen and (min-width: $screen-lg) {
    .section-wrapper {
      max-width: $container-lg - $grid-gutter-width - 200px;
    }
  }

  .section-title {
    @include section-decorator($brand-primary, 4px, 100px, 0);
    font-size: $font-size-xl;
    padding-bottom: 28px;
  }

  .section-btn {
    @include make-button--primary-2();
  }
}

.section--any .section-block {
  margin-bottom: 40px;
}

.section--wiztrust .section-block {
  margin: auto;
  width: 340px;
}

.section-wrapper .section--features--generic {
  position: absolute;
  right: -80px;
  top: 180px;
  width: 80px;

  .card--feature {
    margin-bottom: 10px;
    text-align: center;

    .card-img {
      float: none;
      margin: auto;
      margin-bottom: $card-spacer-y;
      width: initial;

      img {
        @include size(45px, 45px);
      }
    }

    .card-title {
      color: $brand-secondary;
      font-size: $font-size-xxs;
      font-weight: $font-weight-bold;
      position: static;
      text-transform: uppercase;
      transform: initial;
    }
  }

  @media screen and (max-width: $grid-float-breakpoint) {
    display: none;
  }
}

.section--powered_by {
  padding: 0;

  .section-container {
    padding: 0;
    width: 100%;
  }

  .section-block {
    padding: 24px 60px;
    width: auto;
  }

  .section-text {
    a {
      p, span {
        display: inline-block;
        vertical-align: bottom;
      }

      p {
        font-size: 12px;
        font-family: $font-family-secondary;
        margin: 0 5px 0 0;
        text-decoration: underline;
      }

      span {
        width: 90px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.layout-content .section--features,
.layout-event .section--features {
  .section-title-icon {
    display: none;
  }

  .card--feature {
    .card-block {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .card-img {
      margin: 0;
      margin-right: $card-spacer-x;
      width: 20%;

      img {
        @include size(45px, 45px);
      }
    }

    .card-title {
      width: 80%;
    }
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    display: none;
  }
}

.layout-events .section--event_web_publications_highlight {
  background-image: url('../../../../images/events_background.png');
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 10px 30px 0 rgba(230,232,236, 1);
  padding: 40px 0;

  .section-title {
    text-align: center;
  }
}

.layout-tag {
  .section--any,
  .section--experts,
  .section--events {
    .section-title {
      font-size: $font-size-xl;
    }
  }

  .section--jumbotron.section--minus {
   .section-img {
      margin-bottom: 15px;
    }
  }
}

.content-text--blockchain a {
  text-decoration: underline;
}

.layout-experts {
  .layout-hook--main {
    .section--experts {
      margin: 0;

      &:not(.section--experts_highlight) {
        margin: 0 0 72px;
      }

      .gallery {
        margin: 0 -8px;

        .gallery-item {
          padding: 0 8px;
        }
      }

      @media screen and (max-width: $screen-sm) {
        &:not(.section--experts_highlight) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
