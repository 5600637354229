.layout-event {
  .section--live {
    .section-block {
      max-width: 600px; // dirty fix for embed-container.
      margin: 0 auto;
    }
  }

  .section--details {
    background-color: $brand-light;

    .section--left {
      border-right: 1px solid $border-color;
    }

    .section--details_text {
      .content-text {
        color: $brand-dark;
        font-family: $font-family-primary;
        font-size: $font-size-base;
        hyphens: initial;
      }
    }
  }

  .section--spokespersons {
    margin-top: -30px;

    .card-img {
      @include size(40px, 40px);

      &.offie--active {
        img {
          border-color: $brand-primary;
        }
      }
    }

    .gallery--deck .gallery-item {
      width: 14%;
    }

    .offie-dropdown {
      .card-block {
        background-color: #fff;
        margin-top: 15px;
        margin-right: 20px;
      }

      .card-text {
        @include make-font-8();
        text-transform: inherit;

        &:last-child {
          color: $brand-primary;
        }
      }
    }
  }

  .section--details {
    .section-header + .section-composite.section--composite,
    .section--details_text {
      margin-bottom: 0;
    }
  }

  .section--map {
    margin-top: $section-spacer-y;
    margin-bottom: 0;
  }
}
