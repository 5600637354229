.navbar {
  border-radius: 0;
  border-width: 0;
}

.navbar-content,
.navbar-element {
  @include clearfix();
}

.navbar-element--main {
  margin-right: 56px;

  @media screen and (max-width: $screen-sm) {
    margin: 0;
  }
}

@media screen and (max-width: $grid-float-breakpoint-max) {
  .navbar-nav {
    margin-bottom: 0;
    margin-top: 0;
  }
}

@media (min-width: $screen-sm) {
  .navbar-nav {
    float: right;
  }
}

.navbar--app {
  background-color: $brand-light;
  border-width: 0;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.04);
}

.navbar-brand {
  background-color: #fff;
  display: block;
  float: none;
  padding: 0;
  position: relative;

  .navbar-brand-img {
    display: block; // Overrides common/style.css
    height: auto; // Overrides common/style.css
    margin: auto;
    max-width: 100%;
  }

  svg.navbar-brand-img {
    display: block;
    fill: #7E8083;
    height: 50px;
    margin: auto;
    padding: 10px 15px;
    width: 170px;
  }
}

.navbar--channel {
  .navbar-wrapper {
    .navbar-brand {
      display: none;
    }
  }

  @media (min-width: $screen-sm) {
    .navbar-wrapper {
      align-items: center;
      display: flex;
      padding: 0 15px;
  
      .navbar-header {
        margin: 5px 0;

        .navbar-brand {
          display: block;
          height: unset;

          &-img {
            max-height: unset;
            width: 145px;
          }
        }
      }
    }
  
    .navbar-content {
      margin-left: auto;
      width: fit-content;
      
      .offie-block {
        border-bottom: 1px solid $border-color;
        padding-left: 3px;
      }
    }
  }

  @media screen and (max-width: $grid-float-breakpoint-max) {
    background-color: $brand-white;
    border-top-width: 0;
  }

  .nav--channel-1 {
    @include make-nav-variant(
      $text-color, // $link-color,
      $text-color, // $link-color-hover,
      transparent, // $link-bg,
      transparent, // $link-bg-hover,
      $brand-primary, // $link-color-active,
      transparent, // $link-bg-active,
      $text-muted, // $link-color-disabled,
      transparent, // $link-bg-disabled,
    );

    margin: 0;

    > .nav-item {
      > .nav-link {
        font-family: $font-family-maax-publicsenat-bold;
        font-size: 15px;
        height: 46px;
        line-height: 18px;
        padding: 13px 0;
  
        .nav-link-text {
          vertical-align: middle;
        }
  
        .wizicon {
          @include size(10px, 10px);
          vertical-align: middle; // TODO: there's probably a better thing to do.
  
          &.wizicon-search {
            @include size(16px, 16px);
          }
        }
  
        &:hover {
          color: #CC2C1C;
          
          .wizicon-nav-indicator {
            fill: #CC2C1C;
          }
        }
      }

      &.nav-item--search {
        .nav-link {
          margin: 0;
        }
      }

      &.active {
        > .nav-link {
          color: #CC2C1C;

          &:focus,
          &:hover,
          &.offie--active {
            color: #CC2C1C;
          }
        }
      }
    }

    .offie--active.nav-link .wizicon,
    .active > .nav-link .wizicon {
      fill: #CC2C1C;
    }

    @media screen and (min-width: $screen-sm) {
      > .nav-item {
        > .nav-link {
          height: 50px;
          margin: 0 12px;
          padding: 16px 0;

          &::after {
            @include draw-pseudo();
  
            border-bottom: 4px solid #CC2C1C;
            border-bottom-color: #CC2C1C; // Have to be separated, does not work in short form "Zpx solid inherit"
            bottom: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            transition: opacity 0.15s;
          }
        }

        &.active > .nav-link:after,
        > .nav-link.offie--active:after,
        > .nav-link:hover:after {
          opacity: 1;
        }

        > .offie-dropdown {
          left: 12px;
        }
      }
    }
  }

  .nav--channel-2 {
    .nav-link {
      font-family: $font-family-maax-publicsenat-bold;
      font-size: 15px;
      height: 46px;
      margin: 0;
      line-height: 18px;
      padding: 13px 12px;
      text-transform: none;

      &:focus,
      &:hover {
        color: #CC2C1C;
      }
    }
  }
}
