.scrolloff { // Added via javascript
  pointer-events: none;
}

.text-collapse .morelink { /* added by javascript plugin */
  @include make-font-9();
  border-top: 1px solid $brand-primary;
  color: $brand-primary;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px; // Since it's added using JS, it's not quite easy to space.
  padding-top: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 150px;
}

.copyright {
  margin: $section-spacer-y 0;
  text-align: right;
}
