.btn {
  @include button-size($btn-font-size, 18px, $btn-font-size, 1, 0);

  letter-spacing: 0.095em;
  text-decoration: none;
  text-transform: uppercase;

  &:active {
      -webkit-box-shadow: inset 0 3px 15px rgba(0, 0, 0, .05);
    box-shadow: inset 0 3px 15px rgba(0, 0, 0, .05);
  }

  .wizicon {
    @include size($btn-font-size, $btn-font-size);
    vertical-align: bottom;
  }
}

.btn-primary,
.btn-secondary {
  .wizicon {
    fill: #fff;
  }
}

.btn--secondary {
  @include button-huer(
    $brand-secondary,
    #fff,
    transparent,
    $brand-secondary,
    $brand-secondary,
    $brand-secondary,
  );

  font-weight: bold;
}
