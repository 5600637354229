// Card / *
.card-block {
  padding: 0;
}

.card-title {
  @include make-font-2();
}

.card-subtitle {
  @include make-font-8();
}

.card-text {
  @include make-font-1();
  font-family: $font-family-primary;
  font-size: $font-size-base;
}

// Card / Spokesperson
.card--spokesperson {
  .card-img {
    margin: 0 0 $card-spacer-y 0;
    max-width: 120px;

    img {
      border: 1px solid #CCCCCC;
      border-radius: 50%;
    }
  }

  .card-title {
    font-size: $font-size-lg;
    color: $text-color;
    font-family: $font-family-secondary;
  }

  .card-subtitle {
    color: $text-muted;
  }

  .card-element {
    font-size: $font-size-xs;
    color: $brand-secondary;

    a {
      color: $brand-secondary;
    }
  }

  .card-btn {
    cursor: pointer;
  }

  &.card--alt {
    .card-img {
      margin-bottom: 0;
    }

    .card-block {
      background-color: $brand-light;
      border-radius: 4px;
      padding: $card-spacer-y $card-spacer-x;
    }
  }
}

// Card / Event
.card--event {
  .card-img {
    border: 1px solid $border-color;
    margin-bottom: $card-spacer-y;

    &:hover {
      .card-img-overlay {
        background-color: $brand-primary;
        transition: all 0.3s ease-in;
      }
    }
  }

  .card-subtitle,
  .card-text {
    color: $text-muted;
  }

  .card-btn {
    .card-btn-icon {
      display: none;
    }
  }

  $card-event-img-overlay-size: 50px;
  $card-event-img-overlay-spacer-x: 12px;
  $card-event-img-overlay-spacer-y: 12px;
  $card-event-img-overlay-content-size: $card-event-img-overlay-size - (2 * $card-event-img-overlay-spacer-y);

  .card-img-overlay {
    height: 50px;
    background-color: rgba($brand-dark, 0.8);
    padding: 12px; // Resets CSSR;
    top: auto; // Resets CSSR.
    transition: background-color 0.3s ease-in;
  }

  .card-img-overlay-icon,
  .card-img-overlay-text {
    display: inline-block;
    vertical-align: middle;
  }

  .card-img-overlay-icon {
    @include size($card-event-img-overlay-content-size, $card-event-img-overlay-content-size);

    fill: #fff;
    margin-right: ($card-event-img-overlay-spacer-x / 2);
    padding-bottom: 3px;
    padding-top: 3px;
  }

  .card-img-overlay-text {
    @include make-font-9();
    color: #fff;
    line-height: $card-event-img-overlay-content-size;
  }

  .label-live {
    background-color: #000;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #fff;
    font-size: $font-size-xs;
    position: absolute;
    text-transform: uppercase;
    top: 10px;
    right: 10px;
  }
}

// Card / Performer
.card--performer {
  .card-title {
    font-family: $font-family-secondary;
    font-size: $font-size-xl;
  }

  .card-subtitle {
    color: $text-muted;
  }

  .card-img {
    margin-bottom: $card-spacer-y;

    > img {
      border: 1px solid $brand-secondary;
      border-radius: 50%;
      margin: auto;
    }
  }

  .card-subtitle {
    min-height: $line-height-computed * 2;
    padding-bottom: 20px;
    position: relative;

    &:after {
      border-bottom: 2px solid $brand-primary;
      bottom: 0;
      content: '';
      display: block;
      left: 40%;
      position: absolute;
      width: 20%;
    }
  }

  &.card--grid {
    .card-img {
      width: 140px;
    }

    .card-title,
    .card-subtitle {
      text-align: center;
    }
  }

  &.card--list {
    .card-block {
      padding-left: 0;
      padding-right: 0;

      @media screen and (min-width: $screen-sm-min) {
        padding: 0;
      }
    }
  }
}

.card--topic {
  &.card--default {
    @include make-card-layout--list(250px);

    .card-img {
      margin-bottom: $card-spacer-y;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      .card-img {
        margin-bottom: 0;
      }
    }
  }

  &.card--highlight {
    border-bottom: 1px solid $border-color;

    .card-img {
      @include image-view(16, 9);

      margin-bottom: $card-spacer-y;
    }

    .card-block {
      padding: 0;
      padding-bottom: $card-spacer-y;
    }

    .card-title {
      font-size: $font-size-xl;
    }

    .card-text {
      font-size: $font-size-md;
    }
  }
}

// Card Expert
.card--expert {
  display: flex;
  flex-direction: column;

  .card-img {
    @include image-view(1, 1);
    margin: 0 0 16px;
  }

  .card-block {
    padding: 0 0 16px;

    .card-title {
      @include text-line-clamp(2);
      font-family: $font-family-maax-publicsenat-bold;
      font-size: $font-size-lg;
      margin-bottom: 8px;
      line-height: 19px;
    }

    .card-subtitle {
      font-family: $font-family-maax-regular;
      font-size: 14px;
      margin: 0 0 8px;
      line-height: 18px;
    }

    .card-element--tags {
      display: none;
    }

    .card-text {
      font-family: $font-family-maax-regular;
      margin-bottom: 16px;
      line-height: 18px;
    }

    .card-btn {
      .card-btn-icon {
        .wizicon {
          height: 24px;
          width: 29px;

          path {
            color: #CC2C1C;
            fill: #CC2C1C;
          }
        }
      }

      .card-btn-text {
        display: none;
      }
    }
  }
}

.card--asset {
  .card-block {
    padding: 0;
  }

  .card-img {
    margin-bottom: 10px;

    > img {
      border: 1px solid $brand-primary;
    }

    &:after {
      border-color: transparent rgba(#fff, 0.5);
      border-style: solid;
      border-width: 0 100px 100px 0;
      content: '';
      display: block;
      position: absolute;
      right: 1px;
      top: 1px;
    }
  }

  .card-img-icon {
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 3;

    img,
    .wizicon {
      @include size(40px, 40px);

      fill: $brand-primary;
    }
  }

  &.card--folder {
    .card-img-icon {
      border: none;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      top: 50%;

      &,
      .wizicon {
        @include size(55px, 55px);
      }
    }

    .card-img {
      &:after {
        @include size(100%, 100%);
        background-color: rgba($brand-white, 0.5);
        border: none;
        left: 0;
        top: 0;
        transform: initial;
      }
    }
  }

  &.card--alt {
    @include make-card-layout--list(250px);
  }
}

.card--clipping {
  @include make-card-layout--list(180px);

  .card-img {
    margin-right: 20px;
  }

  .card-btn {
    background-color: inherit;
    display: block;

    .card-btn-icon {
      display: none;
    }
  }
}

.card--feature {
  @include make-card-layout--list(40px);

  .card-img {
    padding: 5px;

    .wizicon {
      @include size(30px, 30px);
    }

    img {
      @include size(30px, auto);
    }
  }
  @media screen and (max-width: $screen-xs) {
    .card-block {
      margin-left: 50px;
    }
  }

  .card-title {
    @include make-font-10();
    color: $brand-dark;
    display: inline-block;
    font-weight: $font-weight-regular;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

// Cards
.card--feed-facebook {
  @include card-sizing(10px, 10px);
  @include make-card-layout--list(40px);

  background-color: $brand-light;
  padding: 15px;

  .card-img {
    margin-left: 0;
  }

  .card-title {
    font-family: $font-family-primary;
    font-size: $font-size-base;
    font-weight: 700;
  }

  .card-title-aside {
    color: $text-muted;
    font-size: $font-size-sm;
    font-weight: 400;
  }

  .card-subtitle {
    text-transform: inherit;
  }

  .card-text {
    color: $text-color;
    font-family: $font-family-primary;
  }

  @media screen and (max-width: $screen-sm) {
    .card-img {
        float: left;
        margin-right: 15px;
        width: 40px;

        > img {
          width: 40px;
      }
    }

    .card-block {
      overflow: hidden;
    }
  }
}

.card--contactform {
  label {
    color: $brand-secondary;
    font-weight: $font-weight-regular;
    margin-bottom: 10px;
    padding: 0 5px;
  }

  .checkbox input + label {
    padding: 0;
    margin-bottom: 0;
  }

  .section-btn {
    @include make-button--primary-1();
    margin: 0 auto;
  }
}
