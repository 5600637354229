@font-face {
    font-family: "Maax-Regular";
    font-weight: 400;
    font-style: normal;
    src: url(../../../fonts/Maax-Regular.otf) format("truetype")
}
@font-face {
    font-family: "Maax-Bold";
    font-weight: 700;
    font-style: normal;
    src: url(../../../fonts/Maax-Bold.otf) format("truetype")
}
@font-face {
    font-family: "MaaxPublicSenat-Bold";
    font-weight: 700;
    font-style: normal;
    src: url(../../../fonts/MaaxPublicSenat-Bold.otf) format("truetype")
}
